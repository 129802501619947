/**
 * wrap string with '/' if not already
 * @name getSlashedLink
 * @param {string} link Link for gatsby-link's "to" prop or <a>'s href
 * @returns {string} link wrapped with '/' at the start and end if not already
 * @example
 *  getSlashedLink('about-us') // => '/about-us/'
 *  getSlashedLink('/about-us') // => '/about-us/'
 *  getSlashedLink('about-us/') // => '/about-us/'
 *  getSlashedLink('/about-us/') // => '/about-us/'
 */
export function getSlashedLink(link) {
    let firstChar = link[0];
    let lastChar = link.substr(-1); // Selects the last character
    if (lastChar !== '/') {         // If the last character is not a slash
        link = link + '/';
    }
    if (firstChar !== '/') {
        link = '/' + link
    }
    return link;
}