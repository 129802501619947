import React from "react"
import PropTypes from "prop-types"

var cldData = require("../../config.json")

const ImageMeta = props => {
  var cldImages = cldData.images.resources
  var alt = null
  var src = ""
  var caption = null

  if (!props.publicId) {
    return ""
  }
  for (let i = 0; i < cldImages.length; i++) {
    if (props.publicId === cldImages[i].public_id) {
      alt = cldImages[i].context ? cldImages[i].context.custom.alt : "image"
      src = "/" + cldImages[i].public_id + "." + cldImages[i].format
      break
    }
  }

  return (
    <img src={src} alt={alt} className={props.className} />
    // <Image alt={alt} responsive {...props}>
    //   {props.children}
    // </Image>
  )
}

ImageMeta.propTypes = {
  publicId: PropTypes.string,
  children: PropTypes.element
}

export default ImageMeta
