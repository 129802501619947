import { Link, StaticQuery, graphql } from "gatsby"
import React from "react"
import moment from "moment"

import "./ComFooter.scss"

var classNames = require("classnames")
export default class ComFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleFooter = this.toggleFooter.bind(this)
  }

  toggleFooter() {}

  whatWeekDay(date) {
    var day = date
    switch (day) {
      case "0":
        day = "Sunday"
        return day
        break
      case "1":
        day = "Monday"
        return day
        break
      case "2":
        day = "Tuesday"
        return day
        break
      case "3":
        day = "Wednesday"
        return day
        break
      case "4":
        day = "Thursday"
        return day
        break
      case "5":
        day = "Friday"
        return day
        break
      case "6":
        day = "Saturday"
        return day
    }
  }

  whatShortWeekDay(date) {
    var day = date
    switch (day) {
      case "0":
        day = "Sun"
        return day
        break
      case "1":
        day = "Mon"
        return day
        break
      case "2":
        day = "Tues"
        return day
        break
      case "3":
        day = "Wed"
        return day
        break
      case "4":
        day = "Thur"
        return day
        break
      case "5":
        day = "Fri"
        return day
        break
      case "6":
        day = "Sat"
        return day
    }
  }

  render() {
    var comfooterClass = classNames({
      "com-footer": true
    })

    return (
      <StaticQuery
        query={graphql`
          query footerMyQuery {
            allDataJson {
              nodes {
                siteItems {
                  logos {
                    logo
                  }
                  locations {
                    location {
                      locationName
                      ...locationAddressInfo
                      locationHours {
                        ...locationHoursInfo
                      }
                      socialAccounts {
                        ...socialAccountsInfo
                      }
                      contactInfo {
                        phone
                        email
                        chatLink
                      }
                      extraLinks {
                        statements {
                          ...statementInfo
                        }
                      }
                    }
                  }
                }
              }
            }
            footerStuff: allFooterJson {
              nodes {
                footerItems {
                  blurbText
                }
              }
            }
          }

          fragment locationAddressInfo on DataJsonSiteItemsLocationsLocation {
            streetNumber
            streetName
            cityName
            cityZip
            stateName
            stateAbbr
          }
          fragment locationHoursInfo on DataJsonSiteItemsLocationsLocationLocationHours {
            weekDay
            startTime
            endTime
          }
          fragment socialAccountsInfo on DataJsonSiteItemsLocationsLocationSocialAccounts {
            socialAcount
            socialAcountLink
          }
          fragment statementInfo on DataJsonSiteItemsLocationsLocationExtraLinksStatements {
            title
            pageLink
            linkText
          }
        `}
        render={data => {
          let locationInfo = {}
          let footerInfo = {}
          // let daysOpen = {}
          // let matchingHours = {};
          // let matchingHourDays = {};
          // let uniqueHours = {};
          // let uniqueHourDays = {};

          for (let i = 0; i < data.allDataJson.nodes.length; i++) {
            if (data.allDataJson.nodes[i].siteItems !== null) {
              locationInfo = data.allDataJson.nodes[i]
            }
          }

          // console.log(locationInfo.siteItems[0].locations[0].location[0].streetNumber +" ");

          for (let i = 0; i < data.footerStuff.nodes.length; i++) {
            if (data.footerStuff.nodes[i].footerItems !== null) {
              footerInfo = data.footerStuff.nodes[i]
            }
          }

          // {locationInfo.siteItems[0].locations[0].location[0].locationHours.map(item => (
          //     <React.Fragment key={item.id}>
          //         var hoursInfo = [
          //             {item.weekDay},
          //             {item.startTime},
          //             {item.endTime}
          //         ]
          //         daysOpen.push(hoursInfo)
          //      console.log("DaysOpen" + daysOpen.length)
          //     <h7>Weekday: {this.whatShortWeekDay(item.weekDay)} Start: {item.startTime} End: {item.endTime}</h7>
          //   </React.Fragment>
          // ))}

          // console.log("DaysOpen" + daysOpen.length)
          // console.log(locationInfo);
          return (
            <footer
              id={this.props.pageTitle}
              className={comfooterClass + " " + this.props.pageTitle}
            >
              <section className="section">
                <div className="container footer-super-sets">
                  <div className="columns">
                    <div className="columns footer-blurb">
                      <div>
                        <img
                          role="presentation"
                          src={"/OSSC/DEV/" + "logo-mark.svg"}
                        />
                      </div>
                      <div className="footer-blurb-text">
                        <h5>
                          {this.props.language === "es"
                            ? "Nuestro objetivo es proporcionar a cada paciente una experiencia notable y beneficiosa."
                            : footerInfo.footerItems[0].blurbText}
                        </h5>
                      </div>
                    </div>
                    <div className="column footer-sets">
                      <div className="columns footer-set-1">
                        <div className="column footer-address">
                          <div className="column">
                            <h7>
                              {this.props.language === "es"
                                ? "Ubicación"
                                : "Location"}
                            </h7>
                          </div>
                          <div className="column">
                            <p className="small">
                              <a
                                aria-label="open link in new browser tab"
                                target="_blank"
                                href="https://www.google.com/maps/place/Barefoot+Oral+and+Facial+Surgery/@32.8308905,-79.8237412,17z/data=!4m12!1m6!3m5!1s0x88fe721b3103ea51:0x92efbf8769e58ed!2sBarefoot+Oral+and+Facial+Surgery!8m2!3d32.8308905!4d-79.8215525!3m4!1s0x88fe721b3103ea51:0x92efbf8769e58ed!8m2!3d32.8308905!4d-79.8215525"
                                title={
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].locationName
                                }
                              >
                                {locationInfo.siteItems[0].locations[0]
                                  .location[0].streetNumber + " "}
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].streetName
                                }
                                <br />
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].cityName
                                }
                                {", " +
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].stateAbbr +
                                  " "}
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].cityZip
                                }
                              </a>
                            </p>
                          </div>
                        </div>
                        <div className="column footer-hours">
                          <div className="column">
                            <h7>
                              {this.props.language === "es" ? "Horas" : "Hours"}
                            </h7>
                          </div>
                          <div className="column">
                            <p className="small">
                              {this.props.language === "es"
                                ? "Lun-Jue: "
                                : "Mon-Thurs: "}{" "}
                              <span className="no-word-break">
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].locationHours[0].startTime
                                }
                                –
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].locationHours[0].endTime
                                }
                              </span>
                              <br />
                              {this.props.language === "es"
                                ? "Vie: "
                                : "Fri: "}{" "}
                              <span className="no-word-break">
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].locationHours[4].startTime
                                }
                                –
                                {
                                  locationInfo.siteItems[0].locations[0]
                                    .location[0].locationHours[4].endTime
                                }
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns footer-set-2">
                        <div className="column footer-contact">
                          <div className="column">
                            <h7>
                              {this.props.language === "es"
                                ? "Charlemos"
                                : "Let's Chat"}
                            </h7>
                          </div>
                          <div className="column">
                            <div>
                              <p className="small">
                                <a
                                  href={
                                    "tel:+" +
                                    locationInfo.siteItems[0].locations[0]
                                      .location[0].contactInfo[0].phone
                                  }
                                >
                                  {
                                    locationInfo.siteItems[0].locations[0]
                                      .location[0].contactInfo[0].phone
                                  }
                                </a>
                              </p>
                            </div>
                            <div>
                              <p className="small">
                                <a
                                  href={`mailto:${locationInfo.siteItems[0].locations[0].location[0].contactInfo[0].email}`}
                                >
                                  {
                                    locationInfo.siteItems[0].locations[0]
                                      .location[0].contactInfo[0].email
                                  }
                                </a>
                              </p>
                            </div>
                            <div>
                              <p className="small">
                                <Link
                                  to={
                                    this.props.language === "es"
                                      ? "/es/contactenos-oklahoma-city-ok/"
                                      : "/contact-us-in-mount-pleasant-sc/"
                                  }
                                >
                                  {this.props.language === "es"
                                    ? "Mandanos un Mensaje"
                                    : locationInfo.siteItems[0].locations[0]
                                        .location[0].contactInfo[0].chatLink}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="column footer-social">
                          <div className="column">
                            <h7>
                              {this.props.language === "es"
                                ? "Sociales"
                                : "Social"}
                            </h7>
                          </div>
                          <div className="columns">
                            {locationInfo.siteItems[0].locations[0].location[0].socialAccounts.map(
                              item => (
                                <React.Fragment key={item.id}>
                                  <p
                                    className={`small social-icon ${item.socialAcount}`}
                                  >
                                    <a
                                      aria-label="open link in new browser tab"
                                      href={item.socialAcountLink}
                                      target="_blank"
                                      title={`Follow us on ${item.socialAcount}`}
                                    >
                                      <img
                                        role="presentation"
                                        className={item.socialAcount}
                                        src={
                                          "/OSSC/DEV/" +
                                          item.socialAcount +
                                          ".svg"
                                        }
                                      />
                                    </a>
                                  </p>
                                </React.Fragment>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container sub-footer">
                  <div className="columns">
                    <div className="column sub-footer-copyright">
                      <div>
                        © {moment().format("YYYY")}{" "}
                        {
                          locationInfo.siteItems[0].locations[0].location[0]
                            .locationName
                        }
                        . All rights reserved.
                      </div>
                      <div
                        className="nuvo-brand"
                        dangerouslySetInnerHTML={{
                          __html:
                            this.props.language === "es"
                              ? 'Sitio Web y Producción de Video por <a href="https://nuvolum.com" target="_blank" title="Sitio Web y Producción de Video por Nuvolum">Nuvolum</a>'
                              : 'Website and Video Production by <a href="https://nuvolum.com"  style="font-weight: bold" target="_blank" title="Website and Video Production by Nuvolum">Nuvolum</a>'
                        }}
                      ></div>
                      {this.props.language !== "es" && (
                        <p className="nuvo-brand footer">
                          <a
                            style={{ fontWeight: "bold" }}
                            href="https://getwuwta.com/"
                            title="WUWTA - The Patient Communication Platform That Delivers Superior Outcomes"
                            target="_blank"
                          >
                            Patient experience powered by WUWTA™
                          </a>
                        </p>
                      )}
                    </div>
                    <div className="columns sub-footer-statements">
                      {locationInfo.siteItems[0].locations[0].location[0].extraLinks[0].statements.map(
                        item => (
                          <React.Fragment key={item.id}>
                            <p className="footer">
                              <Link
                                to={`/${item.pageLink}/`}
                                title={item.title}
                              >
                                {item.linkText}
                              </Link>
                            </p>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </footer>
          )
        }}
      />
    )
  }
}
