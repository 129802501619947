/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import ComMenu from "./Menu/ComMenu.js"
import "../../node_modules/@blueprintjs/core/lib/css/blueprint.css"
import "../css/main.scss"
import AOS from "aos"
import "../../node_modules/aos/dist/aos.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CloudinaryContext } from "cloudinary-react"
import ComFooter from "../components/footer/ComFooter"
import { detectIE } from "../components/Toast/comIEDetect"
import SkipContent from "./SkipContent/SkipContent"

config.autoAddCss = false

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: true,
      browserStatus: false,
      browserName: "",
      pageLoaded: false,
      toasted: false,
      iPhoneBottomNavVisible: false
    }

    this.refHandlers = {
      toaster: ref => (this.toaster = ref)
    }

    this.addToast = (toastMessage, toastclassName, toastAction) => {
      this.toaster.show({
        message: toastMessage,
        className: toastclassName,
        action: toastAction
      })
    }

    this.handleiPhoneScroll = this.handleiPhoneScroll.bind(this)
  }

  componentDidMount() {
    this.setState({
      innerHeight: window.innerHeight
    })
    var version = detectIE()
    if (version === false) {
      this.setState({ browserStatus: true })
    }
    if (this.state.browserStatus) {
      window.addEventListener("load", () => {
        this.setState({ pageLoaded: true })
      })
    }
    window.addEventListener("resize", this.handleiPhoneScroll)
  }

  handleiPhoneScroll() {
    let oldHeight = this.state.innerHeight
    if (window.innerHeight > oldHeight) {
      this.setState({
        innerHeight: window.innerHeight,
        iPhoneBottomNavVisible: true
      })
    } else {
      this.setState({
        innerHeight: window.innerHeight,
        iPhoneBottomNavVisible: false
      })
    }
  }

  componentDidUpdate() {
    document.documentElement.classList.remove("theater-is-open")
  }

  toggleToast() {
    this.setState(prevState => ({
      toasted: !prevState.toasted
    }))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleiPhoneScroll)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query test {
            allProcedures: allProceduresJson {
              nodes {
                title
                name
                blurb
              }
            }
            searchIndex: allSitePage(
              filter: {
                context: {
                  language: { ne: "es" }
                  searchHeading: { ne: null }
                  metaDescription: { ne: null }
                }
              }
            ) {
              nodes {
                context {
                  metaDescription
                  searchHeading
                  title
                  url
                }
              }
            }
            allStatefulPages: allSitePage(
              filter: { isCreatedByStatefulCreatePages: { eq: true } }
            ) {
              edges {
                node {
                  path
                }
              }
            }
          }
        `}
        render={data => {
          if (this.state.browserStatus) {
            AOS.init()
            return (
              <>
                <Helmet>
                  <link
                    href="https://fonts.googleapis.com/css?family=Lato:400,700|Raleway:300,700&display=swap"
                    rel="stylesheet"
                  ></link>
                </Helmet>
                <SkipContent id="main-content" />
                <CloudinaryContext
                  responsiveUseBreakpoints="true"
                  cloudName="nuvolum"
                  quality="auto:best"
                  secure={true}
                  crop="scale"
                  width="auto"
                  fetchFormat="auto"
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <main>
                      <ComMenu
                        browserStatus={this.state.browserStatus}
                        pageData={data}
                        language={this.props.language}
                      />
                      <div id="main-content" className={this.props.pageTitle}>
                        {this.props.children}
                      </div>

                      <ComFooter
                        pageTitle={this.props.pageTitle}
                        language={this.props.language}
                      />
                    </main>
                  </div>
                </CloudinaryContext>
              </>
            )
          } else {
            return (
              <>
                <Helmet>
                  <link
                    href="https://fonts.googleapis.com/css?family=Poppins:300,700|Roboto:400,500,700&display=swap"
                    rel="stylesheet"
                  />
                </Helmet>
                <SkipContent id="main-content" />
                <CloudinaryContext
                  responsiveUseBreakpoints="true"
                  cloudName="nuvolum"
                  quality="auto:best"
                  secure={true}
                  crop="scale"
                  width="auto"
                  fetchFormat="auto"
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <main>
                      <ComMenu
                        browserStatus={this.state.browserStatus}
                        pageData={data}
                        language={this.props.language}
                      />
                      <div id="main-content">{this.props.children}</div>
                    </main>
                  </div>

                  <ComFooter
                    pageTitle={this.props.pageTitle}
                    language={this.props.language}
                  />
                </CloudinaryContext>
              </>
            )
          }
        }}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
