/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  schemaPage,
  robots,
  youtubeSchemas
}) {
  const { site, schema } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        schema: allDataJson(filter: { schemaGeneral: { ne: null } }) {
          nodes {
            schemaGeneral
          }
        }
      }
    `
  )

  var schemaGeneral

  schemaGeneral = schema.nodes[0].schemaGeneral

  const metaRobots = robots

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: `/OSSC/DEV/social-image.jpg`
        },
        {
          property: `og:image:width`,
          content: `1200`
        },
        {
          property: `og:image:height`,
          content: `627`
        },
        {
          property: `og:image:alt`,
          content: `Oral Surgery & Dental Implant Specialists South Carolina, located in Mount Pleasant, SC`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `robots`,
          content: metaRobots
        }
      ].concat(meta)}
    >
      {/* <link href="https://fonts.googleapis.com/css?family=Poppins:300,700|Roboto:400,500,700&display=swap" rel="stylesheet"></link> */}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
