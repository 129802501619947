import React from "react"
import PropTypes from "prop-types"

import dots from "../../images/dots.svg"

var classNames = require("classnames")

class HamburgerIcon extends React.Component {
  constructor(props) {
    super(props)
  }

  renderIcon(iconType) {
    var HamburgerIconSpanClass = classNames({
      open: this.props.isOpen && !this.props.isClosing,
    })
    switch(iconType) {
      case "twoLines":
        return (<><span className={HamburgerIconSpanClass}></span>
        <span className={HamburgerIconSpanClass}></span></>)
      case "dots":
        if (this.props.isOpen && !this.props.isClosing) {
          return (<><span className={HamburgerIconSpanClass}></span>
            <span className={HamburgerIconSpanClass}></span></>)
        } else {
          return <img src={dots} />
        }
    }
  }

  render() {

    var cMenuClass = classNames({
      "c-menu": true,
      disabled: this.props.isOpening || this.props.isClosing,
    })

    return (
      <div
        className={cMenuClass}
        onClick={
          this.props.isOpening || this.props.isClosing
            ? false
            : this.props.toggleMenu
        }
      >
        {this.renderIcon(this.props.iconType)}
      </div>
    )
  }
}
HamburgerIcon.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  toggleMenu: PropTypes.func,
}
export default HamburgerIcon
