import { Link, StaticQuery, graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Fuse from "fuse.js"
import Search from "./Search.js"

import HamburgerMenu from "./HamburgerMenu.js"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/pro-light-svg-icons"
import { faWheelchair } from "@fortawesome/pro-light-svg-icons"
import { getSlashedLink } from "@bit/azheng.joshua-tree.bit-utils/getSlashedLink"
import { Image } from "cloudinary-react"
import logoSVG from "../../images/logo.svg"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")

class ComMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      atTopOfPage: true,
      isAlwaysTransparent: false,
      logoChangesOnScroll: false,
      search: {
        hasSearch: true,
        isOpen: false,
        isOpening: false,
        isClosing: false,
        value: "",
        suggestions: [],
        items: [],
        searchTime: ""
      },
      ada: {
        hasADA: false
      },
      logo: {
        hasLogo: true,
        position: "center",
        fullLogo: true
      },
      hamburger: {
        form: "two-lines",
        position: "right",
        isOpen: false,
        isOpening: false,
        isClosing: false
      }
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
  }

  componentDidMount() {
    window.onscroll = () => {
      if (this.props.browserStatus !== false) {
        if (window.pageYOffset !== 0) {
          this.setState({
            atTopOfPage: false
          })
        } else {
          this.setState({
            atTopOfPage: true
          })
        }
      }
    }
    if (this.state.hamburger.isOpen) {
      document.documentElement.classList.add("hamburger-menu-is-open")
    } else if (this.state.hamburger.isClosing) {
      document.documentElement.classList.remove("hamburger-menu-is-open")
    }
  }

  componentDidUpdate() {
    if (this.state.hamburger.isClosing) {
      // console.log("hamburger:isClosing" + this.state.hamburger.isOpen)
      document.documentElement.classList.remove("hamburger-menu-is-open")
    }
  }

  componentWillUnmount() {
    window.onscroll = null
  }

  toggleMenu() {
    if (this.state.hamburger.isOpen && this.props.browserStatus !== false) {
      this.setState(
        prevState => ({
          hamburger: {
            ...prevState.hamburger,
            isClosing: !prevState.hamburger.isClosing
          }
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              hamburger: {
                ...prevState.hamburger,
                isOpen: !prevState.hamburger.isOpen,
                isClosing: !prevState.hamburger.isClosing
              }
            }))
          }, 450)
        }
      )
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (
      !this.state.hamburger.isOpen &&
      this.props.browserStatus !== false
    ) {
      this.setState(prevState => ({
        hamburger: {
          ...prevState.hamburger,
          isOpen: !prevState.hamburger.isOpen,
          isOpening: !prevState.hamburger.isOpening
        }
      }))
      setTimeout(() => {
        this.setState(prevState => ({
          hamburger: {
            ...prevState.hamburger,
            isOpening: !prevState.hamburger.isOpening
          }
        }))
      }, 450)
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    }
  }

  toggleSearch() {
    if (this.state.search.isOpen && this.props.browserStatus !== false) {
      this.setState(prevState => ({
        search: {
          ...prevState.search,
          isClosing: !prevState.search.isClosing
        }
      }))
      setTimeout(() => {
        this.setState(prevState => ({
          search: {
            ...prevState.search,
            isOpen: !prevState.search.isOpen,
            isClosing: !prevState.search.isClosing
          }
        }))
      }, 450)
      // Make sure to sync this setTimeout time with the one in the .scss animation time
    } else if (
      !this.state.search.isOpen &&
      this.props.browserStatus !== false
    ) {
      this.setState(
        prevState => ({
          search: {
            ...prevState.search,
            isOpening: !prevState.search.isOpening,
            isOpen: !prevState.search.isOpen
          }
        }),
        function () {
          setTimeout(() => {
            this.setState(prevState => ({
              search: {
                ...prevState.search,
                isOpening: !prevState.search.isOpening
              }
            }))
          }, 1450)
        }
      )
    }
  }

  handleSearchInputChange(event) {
    if (this.props.browserStatus !== false) {
      var t0 = performance.now()
      var options
      this.setState({ value: event.target.value }, () => {
        let items = []
        if (this.props.language === "es") {
          items = this.props.pageData.allProceduresEs.nodes
          options = {
            shouldSort: true,
            threshold: 0.8,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: ["name", "blurb"]
          }
        } else {
          let nodes = this.props.pageData.searchIndex.nodes

          nodes.forEach(node => {
            items.push(node.context)
          })
          options = {
            shouldSort: true,
            // tokenize: true,
            threshold: 0.4,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              {
                name: "searchHeading",
                weight: 0.7
              },
              {
                name: "metaDescription",
                weight: 0.3
              }
            ]
          }
        }

        var fuse = new Fuse(items, options) // "list" is the item array
        var result = fuse.search(this.state.value)
        var t1 = performance.now()
        var searchTime = parseFloat(((t1 - t0) / 1000).toFixed(2))
        if (searchTime === 0) {
          searchTime = 0.01
        }
        if (result[0]) {
          this.setState(prevState => ({
            search: {
              ...prevState.search,
              suggestions: result,
              searchTime: searchTime
            }
          }))
        } else {
          this.setState(prevState => ({
            search: {
              ...prevState.search,
              suggestions: [],
              searchTime: searchTime
            }
          }))
        }
      })
    }
  }

  handleSearchSubmit(event) {
    event.preventDefault()

    let suggestions = this.state.search.suggestions
    let searchInput = this.state.value
    let searchTime = this.state.search.searchTime
    if (this.props.language === "es") {
      navigate("/es/resultados-de-la-busqueda/", {
        state: { suggestions, searchInput, searchTime }
      })
    } else {
      navigate("/search-results/", {
        state: { suggestions, searchInput, searchTime }
      })
    }
    this.toggleSearch()
  }

  render() {
    var logo
    if (this.props.browserStatus !== false) {
      logo = <img src={logoSVG} className="logo" />
      if (this.state.logoChangesOnScroll) {
        if (!this.state.atTopOfPage) {
          logo = <LogoWhite />
        }
      }
    } else {
      logo = ""
    }

    var comMenuClass = classNames({
      "com-menu": true,
      columns: true,
      "is-mobile": true,
      "is-vcentered": true,
      "has-text-centered-desktop has-text-centered-widescreen has-text-centered-fullhd": true,
      "is-centered": true,
      "com-menu--scrolled": !this.state.atTopOfPage,
      transparent: this.state.isAlwaysTransparent
    })

    if (this.props.browserStatus !== false) {
      //logo = <Logo />
    } else {
      logo = <img src={logoSVG} className="logo" />
    }

    return (
      <StaticQuery
        query={graphql`
          query MyQuery {
            allDataJson {
              nodes {
                topLevelItems {
                  title
                  url
                  linkTitle
                  opensNewTab
                }
                openMenuOrdered {
                  title
                  url
                  targetType
                  description
                  subMenu {
                    targetType
                    title
                    url
                    description
                    # subMenu2 {
                    #   opensNewTab
                    #   title
                    #   url
                    #   description
                    # }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let topLevelItems = {}
          let openMenuOrdered = []
          for (let i = 0; i < data.allDataJson.nodes.length; i++) {
            if (data.allDataJson.nodes[i].topLevelItems !== null) {
              topLevelItems = data.allDataJson.nodes[i].topLevelItems
              openMenuOrdered = data.allDataJson.nodes[i].openMenuOrdered
            }
          }
          let indexToSplit = topLevelItems.length / 2

          var topLevelLeft = topLevelItems.slice(0, indexToSplit + 1)
          var topLevelRight = topLevelItems.slice(indexToSplit + 1)

          return (
            <nav id="mainNav" className={comMenuClass}>
              <Search
                toggleSearch={this.toggleSearch}
                handleSearchInputChange={this.handleSearchInputChange}
                handleSearchSubmit={this.handleSearchSubmit}
                isOpen={this.state.search.isOpen}
                isOpening={this.state.search.isOpening}
                isClosing={this.state.search.isClosing}
                suggestions={this.state.search.suggestions}
                language={this.props.language}
              />
              <div className="column is-hidden-touch side-column is-1-desktop is-1-widescreen is-1-fullhd"></div>
              <div className="column is-2 is-hidden-desktop"></div>
              <div className="column is-4-mobile is-4-tablet is-1-desktop is-1-widescreen is-1-fullhd">
                <SearchIcon toggleSearch={this.toggleSearch} />
                {!this.state.search.hasSearch && <ADA />}
              </div>

              {this.state.search.hasSearch && this.state.ada.hasADA && (
                <div className="column is-1">
                  <ADA />
                </div>
              )}

              {topLevelLeft.map(item => {
                if (!item.opensNewTab) {
                  return (
                    <div key={item.url} className="column is-hidden-touch">
                      <Link
                        className="menu-main-item"
                        to={
                          this.props.language === "es"
                            ? "/es/implantes-dentales-oklahoma-city-ok"
                            : getSlashedLink(item.url)
                        }
                        activeClassName="active"
                        title={
                          this.props.language === "es"
                            ? "Aprenda más sobre implantes dentales"
                            : item.linkTitle
                        }
                      >
                        {this.props.language === "es"
                          ? "Implantes Dentales"
                          : item.title}
                      </Link>
                    </div>
                  )
                } else {
                  return (
                    <div key={item.url} className="column is-hidden-touch">
                      <a
                        aria-label="open link in new browser tab"
                        className="menu-main-item"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          this.props.language === "es"
                            ? "/es/implantes-dentales-oklahoma-city-ok"
                            : item.url
                        }
                        activeClassName="active"
                        title={
                          this.props.language === "es"
                            ? "Aprenda más sobre implantes dentales"
                            : item.aTitle
                        }
                      >
                        {this.props.language === "es"
                          ? "Implantes Dentales"
                          : item.title}
                      </a>
                    </div>
                  )
                }
              })}

              {/* <div className="column is-hidden-touch">
              <Link
                className="menu-main-item"
                to={this.props.language === "es" ? "/es/extraccion-de-muelas-cordales-oklahoma-city-ok/" : topLevelItems[1].url+"/"}
                activeClassName="active"
                title={this.props.language === "es" ? "Aprenda más sobre extracción de muelas cordales" : topLevelItems[1].linkTitle}
              >
                {this.props.language === "es" ? "Muelas Cordales" : topLevelItems[1].title}
              </Link>
            </div>

            <div className="column is-hidden-touch">
              <Link
                className="menu-main-item"
                to={this.props.language === "es" ? "/es/extraccion-de-muelas-cordales-oklahoma-city-ok/" : topLevelItems[2].url+"/"}
                activeClassName="active"
                title={this.props.language === "es" ? "Aprenda más sobre extracción de muelas cordales" : topLevelItems[2].linkTitle}
              >
                {this.props.language === "es" ? "Muelas Cordales" : topLevelItems[2].title}
              </Link>
            </div> */}

              <div className="column is-12 is-hidden-desktop">
                <Link
                  to={this.props.language === "es" ? "/es/" : "/"}
                  className="menu-main-item"
                  activeClassName="active"
                >
                  {logo}
                </Link>
              </div>
              <div className="column is-hidden-touch has-text-centered">
                <Link
                  title="Return to homepage"
                  to={this.props.language === "es" ? "/es/" : "/"}
                  className="menu-main-item"
                  activeClassName="active"
                >
                  {logo}
                </Link>
              </div>

              {topLevelRight.map(item => {
                if (!item.opensNewTab) {
                  return (
                    <div key={item.url} className="column is-hidden-touch">
                      <Link
                        className="menu-main-item"
                        to={
                          this.props.language === "es"
                            ? "/es/implantes-dentales-oklahoma-city-ok"
                            : getSlashedLink(item.url)
                        }
                        activeClassName="active"
                        title={
                          this.props.language === "es"
                            ? "Aprenda más sobre implantes dentales"
                            : item.linkTitle
                        }
                      >
                        {this.props.language === "es"
                          ? "Implantes Dentales"
                          : item.title}
                      </Link>
                    </div>
                  )
                } else {
                  return (
                    <div key={item.url} className="column is-hidden-touch">
                      <a
                        aria-label="open link in new browser tab"
                        className="menu-main-item"
                        target="_blank"
                        rel="noreferrer"
                        href={
                          this.props.language === "es"
                            ? "/es/implantes-dentales-oklahoma-city-ok"
                            : item.url
                        }
                        activeClassName="active"
                        title={
                          this.props.language === "es"
                            ? "Aprenda más sobre implantes dentales"
                            : item.aTitle
                        }
                      >
                        {this.props.language === "es"
                          ? "Implantes Dentales"
                          : item.title}
                      </a>
                    </div>
                  )
                }
              })}

              {/* 
            <div className="column is-hidden-touch">
              <Link
                className="menu-main-item"
                to={this.props.language === "es" ? "/es/acerca-de-nosotros/" : topLevelItems[3].url+"/"}
                activeClassName="active"
                title={this.props.language === "es" ? "Obtenga más información sobre nuestra práctica de cirugía oral." : topLevelItems[3].linkTitle}
              >
                {this.props.language === "es" ? "Acerca de Nosotros" : topLevelItems[3].title}
              </Link>
            </div>

            <div className="column is-hidden-touch">
              <Link
                className="menu-main-item"
                to={this.props.language === "es" ? "/es/historias-de-pacientes/" : topLevelItems[4].url}
                activeClassName="active"
                title={this.props.language === "es" ? "" : topLevelItems[4].linkTitle}
              >
                {this.props.language === "es" ? "Historias de Pacientes" : topLevelItems[4].title}
              </Link>
            </div> */}

              <div className="column is-hidden-touch">
                <a className="menu-main-item" href="tel:+18438495188">
                  (843) 849-5188
                </a>
              </div>

              <div className="column is-4-mobile is-4-tablet is-2-desktop is-2-widescreen is-2-fullhd">
                <HamburgerMenu
                  toggleMenu={this.toggleMenu}
                  isOpen={this.state.hamburger.isOpen}
                  isClosing={this.state.hamburger.isClosing}
                  isOpening={this.state.hamburger.isOpening}
                  atTopOfPage={this.state.atTopOfPage}
                  language={this.props.language}
                  openMenuOrdered={openMenuOrdered}
                />
              </div>
              <div className="column is-2-mobile is-2-tablet is-hidden-desktop"></div>

              {/* <div className="column side-column is-4-mobile is-4-tablet is-1-desktop is-1-widescreen is-1-fullhd"></div> */}
            </nav>
          )
        }}
      />
    )
  }
}

ComMenu.propTypes = {
  pageData: PropTypes.object,
  language: PropTypes.string
}

const SearchIcon = props => {
  return (
    <div>
      <FontAwesomeIcon icon={faSearch} onClick={props.toggleSearch} />
    </div>
  )
}

SearchIcon.propTypes = {
  toggleSearch: PropTypes.func
}

export default ComMenu
